import React from "react";
import { Link } from "gatsby";
import {
  Layout,
  Row,
  Col,
  BackTop,
  Button,
  Popover,
  Divider,
  Modal,
  Image,
} from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import LandingTeamSlider from "../../components/landingPages/landingTeamSlider";
import TaskManagementFeatureGrid from "../../components/landingPages/taskManagementFeatureGrid";
import { TeamPlanListForPopUp } from "../../components/plans/plans";

const { Content } = Layout;

class OnlineCalendarLanding extends React.Component {
  state = {
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
  };
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }

  setModal3Visible(modal3Visible) {
    this.setState({ modal3Visible });
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="DayViewer Task Manager - Easy To Use yet Powerful Online Task Management System"
          />
          <title>DayViewer Task Management System</title>
        </Helmet>
        <Layout>
          <CommonHeader />
        </Layout>
        <Layout style={{ minHeight: "100vh" }}>
          <BackTop />

          <Content className="homepage-background">
            <Row>
              <div className="homepage-banner">
                <Col lg={12} xs={24}>
                  <div className="homepage-jumbotron">
                    <div className="homepage-headlines">
                      <h1>Online Calendar</h1>
                      <h2>Easy To Use Online Calendar</h2>

                      <div style={{ paddingTop: 20 }}>
                        <p>
                          Whether you require monthly, weekly, daily views or an
                          agenda, the DayViewer calendar is designed for ease of
                          use and effectiveness.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button className="homepage-signup-btn">
                      <a
                        href="#"
                        onClick={() =>
                          window.open(
                            "https://app.dayviewer.com/auth/signup",
                            "_self"
                          )
                        }
                      >
                        Sign Up Today - Free To Get Started
                      </a>
                    </Button>
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  {/* <LandingTeamSlider /> */}
                  <video
                    preload="none"
                    controls="controls"
                    poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                    muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="DayViewer Online Weekly Planner"
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/FastPlannerAd-final.mp4"
                  />
                </Col>
              </div>
            </Row>

            <Row>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "5em" }}
              >
                <Col lg={12} xs={24}>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Image
                      // className="centered-and-cropped position-centerquarter-lefttop"
                      // width="1200"
                      // height="600"
                      src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/task-list-planner-home.png"
                      //src="https://via.placeholder.com/150"
                      alt="online calendar"
                      title="online calendar"
                      height="100%"
                      width="100%"
                    />
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  <div style={{ padding: 20 }}>
                    <div>
                      <h1>Why Use DayViewer Calendar Online</h1>

                      <p>
                        We built DayViewer around an online calendar as it was
                        the main view we wanted activities to be focussed
                        around. A Calendar is a well known format, clear and
                        simple and shows a lot of information in one place.
                      </p>
                      <p>
                        Our calendar entries are rich, meaning - you can add
                        fully written documents with formatting, images and file
                        attachments.
                      </p>
                      <p>
                        DayViewer Online Calendar is expandable - so on a small
                        screen (such as a mobile), you can still see your
                        schedule.
                      </p>
                      <p>
                        Information inside the calendar is automatically shown
                        in other views also, so no need to connect together many
                        apps together make your productivity workflow as
                        streamlined as possible.
                      </p>

                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Sign Up - It's Free To Get Started
                          </a>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <div
                className="homepage-jumbotron"
                style={{ textAlign: "left", padding: "5em" }}
              >
                <Row>
                  <Col lg={12} xs={0}>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <h2>Online Calendars For Time Management</h2>
                      <div style={{ paddingRight: 20 }}>
                        <p>
                          Ask yourself, how important is your time to you? do
                          you feel you run out of time daily, procrastinate and
                          eventually not get much done?
                        </p>
                        <p>
                          Writing things down helps a lot in planning and
                          structuring your day to be able to XXXXXX{" "}
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Start Managing Your Tasks Today
                          </a>
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={24}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <picture>
                        <source
                          type="image/webp"
                          srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.webp"
                        ></source>
                        <source
                          type="image/png"
                          srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.png"
                        ></source>
                        <Image
                          src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.png"
                          height="100%"
                          width="100%"
                          alt="Online Calendar"
                          title="Online Calendar"
                        />
                      </picture>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <Row>
              <TaskManagementFeatureGrid />
            </Row>
          </Content>
        </Layout>
        <Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default OnlineCalendarLanding;
